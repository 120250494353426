.searchbar-div > header {
	background-color: white;
}

.searchbar-div {
	padding: 0px 0px;
}

.searchInputDiv > input {
	padding: 6px 0px 7px 55px !important;
}

header.MuiAppBar-colorPrimary.app-bar {
	border: 1px solid #d8d8d8;
	border-radius: 5px;
	box-shadow: none;
}

header.app-bar:hover,
header.app-bar.hover {
	box-shadow: 0 2px 4px -1px RGB(0 0 0 / 20%), 0px 4px 5px 0px RGB(0 0 0 / 14%), 0px 1px 10px 0px RGB(0 0 0 / 12%);
}

div.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
	display: flex;
	justify-content: space-between;
}

div.search {
	display: flex;
}

button.green-button {
	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(178, 178, 178, 0.1)), to(rgba(178, 178, 178, 0.27)));
	color: black;
}

div.MuiButtonGroup-root.MuiButtonGroup-contained button.selected {
	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(27, 68, 183, 0.75)), to(rgba(27, 68, 183, 1)));
	color: white;
}

div.MuiButtonGroup-root.MuiButtonGroup-contained button.green-button:hover {
	background-color: #ffffff;
}

div.MuiButtonGroup-root.MuiButtonGroup-contained button.green-button.selected:hover {
	background-color: #000000;
}

button.hidden {
	display: none;
}

#mfi-searchbar-div div.inputDiv > input {
	width: 100%;
}

@media (min-width: 600px) {
	div.MuiToolbar-regular {
		min-height: 48px;
	}
}
