.dragUnderNode {
	border-bottom: 1px solid black;
}
.dragOverNode {
	border-top: 1px solid black;
}
.dragInNode {
	border: 1px solid black;
}

.group {
	margin: 0;
	padding: 0;
	margin-left: 17px;
}
/* Styles applied to the tree node content. */
.content {
	width: 100%;
	align-items: start;
	cursor: pointer;
}
/* Styles applied to the tree node icon and collapse/expand icon. */
.iconContainer {
	margin-right: 4px;
	width: 15px;
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	svg {
		font-size: 18px;
	}
}
/* Styles applied to the label element. */
.label {
	width: 100%;
	padding-left: 4px;
	position: relative;
}

.label:hover,
.tree:focus > .content .label {
	background-color: rgba(63, 81, 181, 0.06);
	border-radius: 2px;
}

.tree.selected > .content .label {
	background-color: rgba(63, 81, 181, 0.13);
}

.tree.selected > .content .label:hover,
.tree.selected:focus > .content .label {
	background-color: rgba(63, 81, 181, 0.13);
}
