#loading {
	background-color: rgba(0, 0, 0, 0.3);
	height: 100%;
	width: 100%;
	position: fixed;
	margin-top: 0px;
	top: 0px;
	left: 0px;
	bottom: 0px;
	overflow: hidden !important;
	right: 0px;
	z-index: 999999;
}
#loading-center {
	width: 100%;
	height: 100%;
	position: relative;
}
.loader {
	margin: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	position: absolute;
	transform-style: preserve-3d;
}

.assembly,
.comp-3d,
.comp-3d--i,
.cube,
.cube__face,
.pos {
	position: absolute;
	transform-style: preserve-3d;
}

.assembly {
	top: 50%;
	left: 50%;
	transform: rotateX(-45deg) rotateY(-45deg);
}

.comp-3d {
	animation: r 3.2s ease-in-out infinite;
}
.comp-3d--i {
	animation-name: ri;
}
.comp-3d--o {
	animation-name: ro;
}

@keyframes ri {
	0%,
	20% {
		transform: rotateY(-0.5turn);
	}
	50% {
		transform: none;
	}
	100%,
	80% {
		transform: rotateX(-0.5turn);
	}
}
@keyframes ro {
	0%,
	35% {
		transform: rotate(-0.5turn);
	}
	65%,
	100% {
		transform: none;
	}
}
.pos:nth-child(1) {
	transform: scale3d(1, 1, 1) translate3d(4em, 4em, 4em);
}

:not(.pos) > .cube:nth-child(1) {
	transform: translate3d(-4em, -4em, 0em);
}

.pos:nth-child(2) {
	transform: scale3d(1, 1, -1) translate3d(4em, 4em, 4em);
}

:not(.pos) > .cube:nth-child(2) {
	transform: translate3d(-4em, 0em, -4em);
}

:not(.pos) > .cube:nth-child(3) {
	transform: translate3d(-4em, 0em, 0em);
}

:not(.pos) > .cube:nth-child(4) {
	transform: translate3d(-4em, 0em, 4em);
}

.pos:nth-child(3) {
	transform: scale3d(1, -1, 1) translate3d(4em, 4em, 4em);
}

:not(.pos) > .cube:nth-child(5) {
	transform: translate3d(-4em, 4em, 0em);
}

.pos:nth-child(4) {
	transform: scale3d(1, -1, -1) translate3d(4em, 4em, 4em);
}

:not(.pos) > .cube:nth-child(6) {
	transform: translate3d(0em, -4em, -4em);
}

:not(.pos) > .cube:nth-child(7) {
	transform: translate3d(0em, -4em, 0em);
}

:not(.pos) > .cube:nth-child(8) {
	transform: translate3d(0em, -4em, 4em);
}

:not(.pos) > .cube:nth-child(9) {
	transform: translate3d(0em, 0em, -4em);
}

:not(.pos) > .cube:nth-child(10) {
	transform: translate3d(0em, 0em, 0em);
}

:not(.pos) > .cube:nth-child(11) {
	transform: translate3d(0em, 0em, 4em);
}

:not(.pos) > .cube:nth-child(12) {
	transform: translate3d(0em, 4em, -4em);
}

:not(.pos) > .cube:nth-child(13) {
	transform: translate3d(0em, 4em, 0em);
}

:not(.pos) > .cube:nth-child(14) {
	transform: translate3d(0em, 4em, 4em);
}

.pos:nth-child(5) {
	transform: scale3d(-1, 1, 1) translate3d(4em, 4em, 4em);
}

:not(.pos) > .cube:nth-child(15) {
	transform: translate3d(4em, -4em, 0em);
}

.pos:nth-child(6) {
	transform: scale3d(-1, 1, -1) translate3d(4em, 4em, 4em);
}

:not(.pos) > .cube:nth-child(16) {
	transform: translate3d(4em, 0em, -4em);
}

:not(.pos) > .cube:nth-child(17) {
	transform: translate3d(4em, 0em, 0em);
}

:not(.pos) > .cube:nth-child(18) {
	transform: translate3d(4em, 0em, 4em);
}

.pos:nth-child(7) {
	transform: scale3d(-1, -1, 1) translate3d(4em, 4em, 4em);
}

:not(.pos) > .cube:nth-child(19) {
	transform: translate3d(4em, 4em, 0em);
}

.pos:nth-child(8) {
	transform: scale3d(-1, -1, -1) translate3d(4em, 4em, 4em);
}

.pos .cube {
	animation: m 3.2s ease-out infinite;
}
.cube__face {
	margin: -2em;
	width: 4em;
	height: 4em;
	backface-visibility: hidden;
	box-shadow: 0px 0px 2px 2px white inset;
	background: rgba(72, 54, 175, 0);
	animation: animate2 30s ease-in-out infinite;
}

@keyframes animate2 {
	0% {
		background: #074d70;
	}

	20% {
		background: indigo;
	}

	30% {
		background: rgb(116, 0, 116);
	}

	40% {
		background: #700707;
	}

	60% {
		background: rgb(212, 57, 0);
	}

	70% {
		background: rgb(0, 68, 80);
	}

	100% {
		background: #074d70;
	}
}
.cube__face:nth-child(2n) {
	filter: brightness(0.97);
}
.cube__face:nth-child(n + 5) {
	filter: brightness(1.03);
}
.cube__face:nth-child(1) {
	transform: rotateY(0deg) translateZ(2em);
}
.cube__face:nth-child(2) {
	transform: rotateY(90deg) translateZ(2em);
}
.cube__face:nth-child(3) {
	transform: rotateY(180deg) translateZ(2em);
}
.cube__face:nth-child(4) {
	transform: rotateY(270deg) translateZ(2em);
}
.cube__face:nth-child(5) {
	transform: rotateX(90deg) translateZ(2em);
}
.cube__face:nth-child(6) {
	transform: rotateX(-90deg) translateZ(2em);
}

@keyframes m {
	0%,
	5%,
	95%,
	100% {
		transform: none;
	}
	15% {
		transform: translate3d(0, 6em, 0);
	}
	25% {
		transform: translate3d(0, 6em, 6em);
	}
	35%,
	65% {
		transform: translate3d(6em, 6em, 6em);
	}
	75% {
		transform: translate3d(6em, 0, 6em);
	}
	85% {
		transform: translate3d(6em, 0, 0);
	}
}

// Another loader
.lds-ring {
	display: inline-block;
	position: relative;
	width: 16px;
	height: 16px;
	z-index: 999;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 16px;
	height: 16px;
	margin: 0px;
	border: 2px solid #0074ff;
	border-radius: 50%;
	margin-top: 4px;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #0074ff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

//ColorfulLoader CSS
.colorful-loader {
	position: absolute;
	top: 50%;
	left: 50%;
	border-radius: 50%;
	height: 96px;
	width: 96px;
	animation: rotate_3922 1.2s linear infinite;
	background-color: #9b59b6;
	background-image: linear-gradient(#2c49a0, #0084ff, #0084ff);
	//background-image: linear-gradient(#9b59b6, #84cdfa, #5ad1cd);
}

.colorful-loader span {
	position: absolute;
	border-radius: 50%;
	height: 100%;
	width: 100%;
	background-color: #9b59b6;
	background-image: linear-gradient(#2c49a0, #0084ff, #0084ff);
	//background-image: linear-gradient(#9b59b6, #84cdfa, #5ad1cd);
}

.colorful-loader span:nth-of-type(1) {
	filter: blur(5px);
}

.colorful-loader span:nth-of-type(2) {
	filter: blur(10px);
}

.colorful-loader span:nth-of-type(3) {
	filter: blur(25px);
}

.colorful-loader span:nth-of-type(4) {
	filter: blur(50px);
}

.colorful-loader::after {
	content: "";
	position: absolute;
	top: 10px;
	left: 10px;
	right: 10px;
	bottom: 10px;
	background-color: #fff;
	border: solid 5px #ffffff;
	border-radius: 50%;
}

@keyframes rotate_3922 {
	from {
		transform: translate(-50%, -50%) rotate(0deg);
	}

	to {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}

//OtherLoader CSS
.small2 {
	position: absolute;
	height: 100px;
	width: 100px;
	background-color: transparent;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.small1 {
	position: absolute;
	height: 100px;
	width: 100px;
	top: 50%;
	left: 50%;
	transform-origin: center;
	transform: translate(-50%, -50%) rotate(45deg);
	background-color: transparent;
}

.bigcon {
	position: absolute;
	height: 95px;
	width: 95px;
	top: 50%;
	left: 50%;
	transform-origin: center;
	transform: translate(-50%, -50%) rotate(-45deg);
	background-color: transparent;
	animation: bigcon 2s infinite linear;
	animation-delay: 0.25s;
}

.ball {
	border-radius: 50%;
	position: absolute;
}

.small-loader {
	width: 25px;
	height: 25px;
	animation: small 2s infinite ease;
	box-shadow: 0 0px 0 1px rgba(0, 0, 0, 0.3);
	background-color: #46b9ff;
}

.small-loader:nth-child(1) {
	top: 0%;
	left: 0%;
}

.small-loader:nth-child(2) {
	top: 0%;
	right: 0%;
}

.small-loader:nth-child(3) {
	right: 0%;
	bottom: 0%;
}

.small-loader:nth-child(4) {
	bottom: 0%;
	left: 0%;
}

.big {
	width: 20px;
	height: 20px;
	border-radius: 15px;
	box-shadow: 0px 0px 10px #54f7f8, 0px 0px 20px #54f7f8, 0px 0px 30px #54f7f8, 0px 0px 50px #54f7f8,
		0px 0px 60px #54f7f8;
	z-index: 1;
	background-color: #54f7f8;
	animation: bigball 1s infinite linear;
}

.smallball1 {
	animation-delay: -1.75s;
}
.smallball6 {
	animation-delay: -1.5s;
}
.smallball2 {
	animation-delay: -1.25s;
}
.smallball7 {
	animation-delay: -1s;
}
.smallball3 {
	animation-delay: -0.75s;
}
.smallball8 {
	animation-delay: -0.5s;
}
.smallball4 {
	animation-delay: -0.25s;
}
.smallball5 {
	animation-delay: -0s;
}

@keyframes bigcon {
	0% {
		transform-origin: center;
		transform: translate(-50%, -50%) rotate(45deg);
	}
	100% {
		transform-origin: center;
		transform: translate(-50%, -50%) rotate(405deg);
	}
}

@keyframes small {
	0% {
		transform: scale(1);
		background-color: #46b9ff;
	}
	10% {
		transform: scale(1.3);
		background-color: #54f7f8;
	}
	15% {
		transform: scale(1);
	}
	25% {
		transform: scale(1);
		background-color: #46b9ff;
	}
	100% {
		transform: scale(1);
		background-color: #46b9ff;
	}
}
