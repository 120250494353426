.update-card-content {
	padding: 10px 0px;
	transition: 0.1s;
	margin: 0;

	border-top: 0px !important;
	box-shadow: none;
	padding-left: 10px;
	border-radius: 8px !important;
}
