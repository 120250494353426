:root {
	--primary: #0676ed;
	--background: #222b45;
	--warning: #f2a600;
	--success: #12c99b;
	--error: #e41749;
	--dark: #151a30;
}

.alert {
	position: fixed;
	top: 150px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 10000;
	min-height: 67px;
	width: 560px;
	max-width: 90%;
	border-radius: 12px;
	padding: 16px 22px 17px 20px;
	display: flex;
	align-items: center;
}
.alert-warning {
	background: var(--warning);
}
.alert-success {
	background: var(--success);
}
.alert-primary {
	background: var(--primary);
}
.alert-dark {
	background: var(--dark);
}
.alert-error {
	background: var(--error);
}
.alert .icon__wrapper {
	height: 34px;
	width: 34px;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.253);
	display: flex;
	align-items: center;
	justify-content: center;
}
.alert .icon__wrapper span {
	font-size: 21px;
	color: #fff;
}
.alert p {
	color: #fff;
	font-family: Verdana;
	margin-left: 10px;
	margin-top: 15px;
}
.alert p a,
.alert p a:visited,
.alert p a:active {
	color: #fff;
}
.alert .open {
	margin-left: auto;
	margin-right: 5px;
}
.alert .close,
.alert .open {
	color: #fff;
	font-size: 18px;
	top: 0;
	right: 0;
	position: absolute;
	padding: 3px;
	cursor: pointer;
}
