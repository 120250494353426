.search {
	position: relative;
	border-radius: 4px;
	color: black;
	margin-left: 0;
	width: 100%;
}

.searchIcon {
	width: 56px;
	height: 100%;
	position: absolute;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

.inputRoot {
	color: inherit;
}

.inputInput {
	padding: 8px 8px 8px 56px !important;
	width: 100%;
	transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
