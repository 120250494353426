.root {
	margin: "8px";
}

.accordionButton {
	focus {
		outline: "none";
		box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.13) !important;
	}
}
