/* ChangeItem.css */

.change-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-bottom: 10px;
	background-color: #f9f9f9;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.change-item .item-title {
	flex: 1;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 50%;
}

.change-counts {
	display: flex;
}

.addition-count {
	color: green;
	margin-right: 10px;
}

.change-count {
	color: #999900;
	margin-right: 10px;
}

.deletion-count {
	color: red;
}

/*---------------*/
/***** Honey *****/
/*---------------*/

/*div.effect-honey {*/
/*	background: #373e53;*/
/*}*/

/*div.effect-honey .effect-honey-image {*/
/*	opacity: 0.9;*/
/*	-webkit-transition: opacity 0.35s;*/
/*	transition: opacity 0.35s;*/
/*}*/

div.effect-honey:hover .effect-honey-image {
	opacity: 0.5;
}

/*div.effect-honey .figcaption::before {*/
/*	position: absolute;*/
/*	bottom: 0;*/
/*	left: 0;*/
/*	width: 100%;*/
/*	height: 10px;*/
/*	background: #fff;*/
/*	content: '';*/
/*	-webkit-transform: translate3d(0,10px,0);*/
/*	transform: translate3d(0,10px,0);*/
/*}*/

div.effect-honey .eh-item-right,
div.effect-honey .eh-item-left {
	position: relative;
	bottom: 0;
	left: 0;
	/*padding: 1em 1.5em;*/
	width: 5%;
}

div.effect-honey .eh-item-right {
	text-align: right;
	-webkit-transform: translate3d(-10px, 0, 0);
	transform: translate3d(-10px, 0, 0);
}

div.effect-honey .eh-item-left {
	text-align: left;
	-webkit-transform: translate3d(+10px, 0, 0);
	transform: translate3d(+10px, 0, 0);
}

/*div.effect-honey .item-title i {*/
/*	font-style: normal;*/
/*	opacity: 0;*/
/*	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;*/
/*	transition: opacity 0.35s, transform 0.35s;*/
/*	-webkit-transform: translate3d(0,-30px,0);*/
/*	transform: translate3d(0,-30px,0);*/
/*}*/

div.effect-honey .figcaption::before,
div.effect-honey .eh-item-left,
div.effect-honey .eh-item-right {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}

div.effect-honey:hover .figcaption::before,
div.effect-honey:hover .eh-item-left,
div.effect-honey:hover .eh-item-right,
div.effect-honey:hover .eh-item i {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

div.effect-honey:hover {
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	cursor: pointer;
}
