.applications {
	height: 100vh;
	width: 100vw;
	background-color: #52a7c1;
	background-image: linear-gradient(315deg, #52a7c1 0%, #b3f6d8 74%);
	z-index: 999999999;
	position: absolute;
	top: 0px;
	left: 0px;
	display: none;
	-webkit-animation: scale-in-tl 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: scale-in-tl 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	display: flex;
	align-items: center;
	justify-content: center;
}
.applicationsOpen {
	-webkit-animation: scale-in-tl 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: scale-in-tl 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.applicationsClose {
	-webkit-animation: scale-out-tl 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
	animation: scale-out-tl 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@-webkit-keyframes scale-in-tl {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		-webkit-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		opacity: 1;
	}
}
@keyframes scale-in-tl {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		-webkit-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		opacity: 1;
	}
}
@-webkit-keyframes scale-out-tl {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		opacity: 1;
	}
}
@keyframes scale-out-tl {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		opacity: 1;
	}
}
.parallax > use {
	animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
	animation-delay: -2s;
	animation-duration: 7s;
}
.parallax > use:nth-child(2) {
	animation-delay: -3s;
	animation-duration: 10s;
}
.parallax > use:nth-child(3) {
	animation-delay: -4s;
	animation-duration: 13s;
}
.parallax > use:nth-child(4) {
	animation-delay: -5s;
	animation-duration: 20s;
}
@keyframes move-forever {
	0% {
		transform: translate3d(-90px, 0, 0);
	}
	100% {
		transform: translate3d(85px, 0, 0);
	}
}
.exportedElement {
	background-image: linear-gradient(#f0f0ec, #f0f0ec), linear-gradient(#f0f0ec, #f0f0ec);
	background-position-x: left, left;
	background-position-y: top, bottom;
	background-repeat: no-repeat no-repeat;
	background-size: 100% 25%, 100% 74%;
	border-radius: 0.25em;
	box-shadow: rgba(0, 0, 0, 0.35) 0 0 0.375em;
	display: block;
	max-width: 28.125em;
	padding: 2.5em;
	text-align: center;
	width: 400px;
	z-index: 99;
}
.exportedElement > header {
	display: block;
}
.exportedElement > header > div {
	border-radius: 50%;
	display: block;
	margin: 0 auto;
	position: relative;
}
.exportedElement > header > div > img {
	border-radius: 50%;
	border-style: none;
	box-shadow: #f0f0ec 0 0 0 0.625em;
	height: auto;
	width: 12.5em;
	border-radius: 50%;
	background-color: white;
}
.exportedElement > header > h1 {
	font-family: Montserrat, sans-serif;
	font-size: 32px;
	margin: 0.67em 0;
	text-transform: uppercase;
}
.exportedElement > header > h4 {
	font-family: Montserrat, sans-serif;
	margin: 0;
}
.exportedElement > header > p {
	margin: 1em 0;
}
.exportedElement > footer {
	display: block;
}
.exportedElement > footer > nav {
	display: block;
	margin-bottom: 1.875em;
}
.exportedElement > footer > nav > a:nth-child(1) {
	background-color: transparent;
	display: inline-block;
}
.exportedElement > footer > nav > a:nth-child(1):active {
	outline: 0;
}
.exportedElement > footer > nav > a:nth-child(1):hover {
	outline: 0;
}
.exportedElement > footer > nav > a:nth-child(1) > svg {
	border: 0.0625em solid #1976d2;
	border-radius: 5px;
	fill: #1976d2;
	height: 1.25em;
	margin: 0.625em;
	padding: 0.625em;
	transition: all 350ms;
	width: 1.5em;
}
.exportedElement > footer > nav > a:nth-child(1) > svg:hover {
	border-color: #2196f3;
	fill: #2196f3;
	transform: scale(1.1);
}
.exportedElement > footer > nav > a:nth-child(1) > svg:not(:root) {
	overflow: hidden;
}
.exportedElement > footer > nav > a:nth-child(2) {
	background-color: transparent;
	display: inline-block;
}
.exportedElement > footer > nav > a:nth-child(2):active {
	outline: 0;
}
.exportedElement > footer > nav > a:nth-child(2):hover {
	outline: 0;
}
.exportedElement > footer > nav > a:nth-child(2) > svg {
	border: 0.0625em solid #1976d2;
	border-radius: 5px;
	fill: #1976d2;
	height: 1.25em;
	margin: 0.625em;
	padding: 0.625em;
	transition: all 350ms;
	width: 1.5em;
}
.exportedElement > footer > nav > a:nth-child(2) > svg:hover {
	border-color: #2196f3;
	fill: #2196f3;
	transform: scale(1.1);
}
.exportedElement > footer > nav > a:nth-child(2) > svg:not(:root) {
	overflow: hidden;
}
.exportedElement > footer > nav > a:nth-child(3) {
	background-color: transparent;
	display: inline-block;
}
.exportedElement > footer > nav > a:nth-child(3):active {
	outline: 0;
}
.exportedElement > footer > nav > a:nth-child(3):hover {
	outline: 0;
}
.exportedElement > footer > nav > a:nth-child(3) > svg {
	border: 0.0625em solid #1976d2;
	border-radius: 5px;
	fill: #1976d2;
	height: 1.25em;
	margin: 0.625em;
	padding: 0.625em;
	transition: all 350ms;
	width: 1.5em;
}
.exportedElement > footer > nav > a:nth-child(3) > svg:hover {
	border-color: #2196f3;
	fill: #2196f3;
	transform: scale(1.1);
}
.exportedElement > footer > nav > a:nth-child(3) > svg:not(:root) {
	overflow: hidden;
}
.exportedElement > footer > a {
	background-color: #1976d2;
	border-radius: 5px;
	color: #ffffff;
	display: inline-block;
	font-family: Montserrat, sans-serif;
	padding: 10px 50px;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 350ms;
}
.exportedElement > footer > a:hover {
	background-color: #2196f3;
	outline: 0;
	transform: scale(1.05);
}
.exportedElement > footer > a:active {
	outline: 0;
}

.bg-bubbles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.bg-bubbles li {
	position: absolute;
	list-style: none;
	display: block;
	width: 200px;
	height: 200px;
	background-color: rgba(255, 255, 255, 0.15);
	bottom: -160px;
	animation: square 100s infinite;
	transition-timing-function: linear;
}
.bg-bubbles li:nth-child(1) {
	left: 10%;
}
.bg-bubbles li:nth-child(2) {
	left: 20%;
	width: 80px;
	height: 80px;
	animation-delay: 4s;
	animation-duration: 37s;
}
.bg-bubbles li:nth-child(3) {
	left: 25%;
	animation-delay: 8s;
}
.bg-bubbles li:nth-child(4) {
	left: 40%;
	width: 60px;
	height: 60px;
	animation-duration: 44s;
	background-color: rgba(255, 255, 255, 0.25);
}
.bg-bubbles li:nth-child(5) {
	left: 70%;
	animation-delay: 3s;
}
.bg-bubbles li:nth-child(6) {
	left: 80%;
	width: 120px;
	height: 120px;
	animation-delay: 6s;
	background-color: rgba(255, 255, 255, 0.2);
}
.bg-bubbles li:nth-child(7) {
	left: 32%;
	width: 160px;
	height: 160px;
	animation-delay: 14s;
}
.bg-bubbles li:nth-child(8) {
	left: 55%;
	width: 320px;
	height: 320px;
	animation-delay: 35s;
	animation-duration: 30s;
}
.bg-bubbles li:nth-child(9) {
	left: 25%;
	width: 110px;
	height: 110px;
	animation-delay: 2s;
	animation-duration: 80s;
	background-color: rgba(255, 255, 255, 0.3);
}
.bg-bubbles li:nth-child(10) {
	left: 40%;
	width: 160px;
	height: 160px;
	animation-delay: 22s;
}
.bg-bubbles li:nth-child(11) {
	left: 85%;
	animation-delay: 22s;
}
.bg-bubbles li:nth-child(12) {
	left: 22%;
	width: 80px;
	height: 80px;
	animation-delay: 2s;
	animation-duration: 117s;
}
.bg-bubbles li:nth-child(13) {
	left: 57%;
	animation-delay: 4s;
	animation-duration: 28s;
}
.bg-bubbles li:nth-child(14) {
	left: 43%;
	width: 60px;
	height: 60px;
	animation-duration: 122s;
	background-color: rgba(255, 255, 255, 0.25);
}
.bg-bubbles li:nth-child(15) {
	left: 50%;
	animation-delay: 10s;
}
.bg-bubbles li:nth-child(16) {
	left: 89%;
	width: 120px;
	height: 120px;
	animation-delay: 3s;
	animation-duration: 22s;
	background-color: rgba(255, 255, 255, 0.2);
}
.bg-bubbles li:nth-child(17) {
	left: 38%;
	width: 160px;
	height: 160px;
	animation-delay: 7s;
}
.bg-bubbles li:nth-child(18) {
	left: 45%;
	width: 20px;
	height: 20px;
	animation-delay: 15s;
	animation-duration: 40s;
}
.bg-bubbles li:nth-child(19) {
	left: 17%;
	width: 10px;
	height: 10px;
	animation-delay: 2s;
	animation-duration: 40s;
	background-color: rgba(255, 255, 255, 0.3);
}
.bg-bubbles li:nth-child(20) {
	left: 92%;
	width: 160px;
	height: 160px;
	animation-delay: 11s;
}
@keyframes square {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-150vh) rotate(600deg);
	}
}
@keyframes square {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-150vh) rotate(600deg);
	}
}

.loginBackground {
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100vh;
	width: 100vw;
	background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
}

.loginBox {
	max-width: 444px;
	animation-duration: 2s;
	animation-delay: 1s;
	animation-fill-mode: both;
	background-color: #ffffff;
	margin-top: 5%;
	padding: 0px 40px 40px 40px;
	margin-left: auto;
	margin-right: auto;
}

.fadeInDown {
	max-width: 444px;
	animation-name: fadeInDown;
	animation-duration: 2s;
	animation-delay: 1s;
	animation-fill-mode: both;
	background-color: #ffffff;
	transform: translate(calc(100vw - 50%), 40px);
	position: relative;
	top: 150px;
	left: calc(50% - 222px);
	padding: 0px 40px 40px 40px;
	border-radius: 10px;
	border: solid 1px #000000;
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
	50% {
		opacity: 1;
		transform: translate3d(0, 20%, 0);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

.iq-menu-horizontal .iq-sidebar-menu li.menu-open > a,
.iq-menu-horizontal .iq-sidebar-menu li:hover {
	background-color: transparent !important;
}
