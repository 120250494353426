.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
	padding-bottom: 10px;
}

.formField {
	width: "100%";
}

.listbox {
	box-sizing: "border-box", ul {
		padding: 0;
		margin: 0;
	}
}
