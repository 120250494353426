.cardHeaderLocationText {
	text-decoration: none !important;
	color: #808080 !important;
}
.folderIcon {
	text-decoration: none !important;
	color: #808080 !important;
	:hover {
		color: #0a58ca !important;
	}
}
.cardHeaderLocationHolder {
	padding: 14px !important;
	background-color: #e9ecef;
	border-radius: 3px;
}
.cardLocationText {
	text-decoration: none !important;
}
.activeBreadcrumb {
	color: #6c757d !important;
}
.breadCrumbItemHolder {
	padding: 0px 10px !important;
}
.cardShadow {
	box-shadow: 0px 0px 10px 0px #b5b5b5;
	border: solid 1px #dddddd;
	border-radius: 8px;
}
.icon {
	:hover {
		background-color: #b5b5b5;
	}
}
