.header-label {
	font-size: 0.7em;
}
.bold {
	font-weight: bold;
}
.red {
	color: red;
	/*filter: brightness(270%);*/
}
.footer {
	font-size: 0.7em;
}
