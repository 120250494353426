div#setup-form-settings-dialog
	div.MuiDialogContent-root
	span.MuiButtonBase-root.MuiIconButton-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiIconButton-colorPrimary {
	padding: 0 22px;
	border-right: 2px solid black;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	height: 25px;
}

div.MuiDialogContent-root div.MuiTreeItem-content:hover {
	background-color: rgba(0, 0, 0, 0.04);
}
