.checklist th {
	height: 150px;
	vertical-align: bottom;
	text-align: center;
	position: sticky;
	background-color: white !important;
	top: 0;
	z-index: 1;
}

.checklist table > * > * {
	border: 1px solid black !important;
}

.checklist table {
	border: #b4b9bf;
}

.checklist .vertical {
	writing-mode: vertical-rl;
	transform: scale(-1, -1);
}

.reference {
	color: red;
}

.checklist td {
	padding: 0 4px;
	height: 1px;
}

.checklist td.reference {
	padding: 0px;
}
