/* ChangeSummary.css */

.change-summary {
	border: 1px solid #ddd;
	padding: 20px;
	border-radius: 5px;
	background-color: #f9f9f9;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.change-summary h2 {
	color: #333;
	margin-bottom: 10px;
}

.change-summary h3 {
	color: #666;
	margin-bottom: 5px;
}

.change-summary ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.change-summary li {
	padding: 5px 0;
}

/* Style the list items differently based on type */
.change-summary ul:nth-child(odd) li {
	background-color: #f0f0f0;
}

.change-summary ul:nth-child(even) li {
	background-color: #f5f5f5;
}

/* Add some spacing between different sections */
.change-summary div:not(:first-child) {
	/*margin-top: 20px;*/
}
