.background-gray {
	background-color: #d9d9d9;
}
.invisible-input {
	border: 0;
	outline: 0 !important;
	padding: 0 0 0 5px !important;
	margin: 0 0 0 0 !important;
	flex: 1 1 auto;
	//height: 100%;
}
.crf textarea {
	width: 100%;
	box-sizing: border-box;
}

.invisible-input:focus {
	outline: none;
	box-shadow: none;
}
