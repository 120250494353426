// Required
@import "bootstrap/scss/functions";

// Default variable overrides
$blue: #0e1278;
$secondary: #a5d3ff;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1920px,
);
