.openPoints .vertical {
	transform: scale(-1);
	writing-mode: vertical-rl;
	word-wrap: normal;
}
div.openPoints > table > thead > tr.secondHeader > td {
	height: 250px;
}
div.openPoints {
	width: 100%;
	font-family: Carlito;
}
.thickLeftBorder {
	border-left: 2px solid rgb(222, 226, 230) !important;
}

.stickyHeader {
	position: sticky;
	top: 0;
}
.filterIcon {
	fill: gray;
	stroke: gray;
	transition: 0.3s;
	fill-opacity: 0;
	white-space: nowrap;
}

.filterIcon:hover {
	fill-opacity: 1;
}

td {
	font-weight: 300 !important;
}
