//#objectPanels {
//  display: flex;
//  flex-wrap: wrap;
//  flex-basis: 100%;
//  height: 100%;
//  width: 100%;
//  margin-left: 100px;
//  margin-right: 100px;
//}
//
//div#template-mfi-view div.workspace-tree-container {
//  height: calc(100% - 28px);
//}
//
//div.workspace-tree-container {
//  //height: calc(100% - 175px);
//  height: calc(100% - 40px);
//  position: relative;
//}
//
//div.workspace-tree-container.overall-panel {
//  //height: calc(100% - 84px);
//  height: calc(100% - 222px);
//  position: relative;
//}
//
////This really does set the height of the panel trees (The other 10 places don't do anything)
//div.data-warehouse-tree-container {
//  height: calc(100% - 52px);
//  position: relative;
//}
//
//div.data-warehouse-tree-container.dw-panel, div.data-warehouse-tree-container.models-panel {
//  height: calc(100% - 213px);
//}
//
////div.data-warehouse-tree-container.overall-panel {
////    height: calc(100% - 84px);
////    position: relative;
////}
//
//div.comparison-container {
//  height: calc(100% - 20px);
//  position: relative;
//  overflow-y: visible;
//}
//
//div#layout-panel div.tab-panel-box>div {
//  //overflow: hidden;
//}
//
//div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline {
//  padding: 0;
//}
//
//div#overlay, div#datawarehouse-panel div.tree, div#template-panel div.tree, div#workspace-panel div.tree{
//  width: 100%;
//  height: 100%;
//  //position: absolute;
//}
//
//div#datawarehouse-panel div.tree{
//  width: 100%;
//  height: calc(100% - 0px);
//  position: absolute;
//}
//
//div#layout-panel div.tree {
//  height: 100%;
//  width: 100%;
//  //position: absolute;
//}
//
//div.layout-tree-container {
//  height: 100%;
//  width: 100%;
//}
//
////div#layout-panel div.tree {
////    height: 100%;
////    width: 100%;
////}
//
////Have to do all this crazy stuff to get the tree in the setupFormSettingsDialog to look normal, TODO: Look into fixing a better way
//div#setup-form-settings-dialog div.tree{
//  width: calc(100% -  50px);
//  height: calc(100% - 190px);
//  position: absolute;
//  padding-top: 12px;
//}
//
//div#setup-form-settings-dialog div.tree>ul, div#setup-form-settings-dialog div.tree>ul>div {
//  height: 100% !important;
//}

div.fading {
	opacity: 0.3;
	border-radius: 5px;
	border: 5px solid RGB(0 68 255);
}

div.field-fading {
	opacity: 0.5;
}

div#overlay {
	display: flex;
	text-align: center;
	vertical-align: middle;
	align-items: center;
	justify-content: center;
	font-weight: 900;
	position: absolute;
	font-size: 2em;
	pointer-events: none;
	background: white;
	//height: 50%;
	//top: 50%;
	font-family: Courier, Georgia, Serif, serif;
	//z-index: -1;
}

//div#layout-panel div.tab-panel, div#layout-panel div.tab-panel-box {
//  //height: calc(100% - 165px);
//}
//
//div.panel {
//  height: 100%;
//}
//
//div#object-workspace-container div.ag-theme-alpine {
//  height: calc(100% - 35px);
//}
//
//div#object-workspace-container div.ag-theme-alpine div.ag-root-wrapper-body {
//  height: 100%;
//}
//
//h3 {
//  text-align: center;
//}
//
//#object-workspace-container {
//  width: 100%;
//  height: calc(100% - 0px);
//
//  margin-right: auto;
//  margin-left: auto;
//}
//
//div#obj-wrksp-dw-view {
//
//}
//
//div#obj-wrksp-outer-grid {
//  flex-wrap: nowrap;
//  overflow-x: auto;
//  background-color: #F3F7FD;
//  padding-top: 20px;
//  padding-bottom: 20px;
//}
//
//div#obj-wrksp-mfi-view {
//  width: 25%;
//  min-width: 500px;
//}
//
//div#models-mfi {
//  width: 100%;
//  min-width: 600px;
//}
//
////Layout the three panels
//div#template-mfi-view, div#obj-wrksp-dw-view {
//  //width: 400px;
//  min-width: 150px;
//  width: 50%;
//  //width: 20%;
//}
//
////Layout the three panels
//div#obj-wrksp-dw-view {
//  //width: 400px;
//  min-width: 400px;
//  width: 50%;
//  //width: 20%;
//}
//
//div#obj-wrksp-layout-view {
//  //width: 650px;
//  //min-width: 730px;
//  width: 45%;
//  min-width: 1180px;
//}
//
//div#obj-wrksp-properties {
//  min-width: 750px;
//  width: 30%;
//  //min-width: 700px;
//  //width: 30%;
//}
//
//div#obj-wrksp-dev-form {
//  //min-width: 700px;
//  width: 30%;
//}
//
//div.dialog div.MuiDialog-paper {
//  min-width: 350px;
//}
//
//#object-list-dialog div.MuiDialog-paper{
//  width: 75%;
//  height: 75%;
//  max-width: 100%;
//}
//
//.large-dialog div.MuiDialog-paper {
//  width: 75%;
//  min-height: 800px;
//  height: 800px;
//  max-width: 900px;
//  //background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(255, 255, 255, .15)), to(rgba(0, 0, 0, .25)));
//}
//
//div#setup-sheets-dialog.large-dialog div.MuiDialog-paper {
//  width: 100%;
//  max-width: 1200px;
//}
//.small-dialog div.MuiDialog-paper {
//
//}
//
//.presentation-iframe {
//  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
//  height: 100%;
//  width: 100%;
//  overflow-x: auto;
//  overflow-y: auto;
//  border-width: 0px;
//}
//
//.MuiBox-root.tab-panel-box {
//  padding: 0px;
//  height: calc(100% - 36px)
//}
