.inputPadding {
	padding: 5px;
}
.centerText {
	text-align: center;
}
.buttonTopMargin {
	margin-top: 15px;
}
.contentEnd {
	justify-content: flex-end;
}
.newButton {
	height: 40px;
}
.listGrid {
	height: calc(100% - 50px);
}
.rotateText {
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	align-content: center;
	padding: 5px 0px;
}
.bold {
	font-weight: bold;
}
.thickBorder {
	border: 2px solid black;
}
.border {
	border: 1px solid black;
	border-collapse: collapse;
}
.heading {
	background: #cccccc;
}
.fullWidth {
	height: 86%;
}
.smallText {
	font-size: 8px;
}
