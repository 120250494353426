.fullHeight {
	height: 100%;
	overflow-y: auto;
}
.dialogGradientBlueBackground {
	background: none;
	margin-block-start: 0em;
	margin-block-end: 0em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: bold;
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	align-items: center;
	justify-content: flex-end;
	padding: 1.25rem;
	border-top: 1px solid #dee2e6;
	border-bottom-right-radius: calc(0.3rem - 1px);
	border-bottom-left-radius: calc(0.3rem - 1px);
}

.dialogTitles {
	background: none;
	text-align: left;
	display: flex;
	font-weight: bold;
	flex-shrink: 0;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-bottom: 1px solid #dee2e6;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}

.fontWhite {
	color: white;
}

.grayGradientBackground {
	background: none;
	padding: 20px !important;
}

.dialogTitles > h2 {
	width: 100%;
	color: #3e3e3e;
	line-height: 1.5;
	font-size: 1.5rem;
	margin-top: -15px;
	font-weight: 600;
	margin-left: 7px;
	font-family: sans-serif;
}
