div#dialog-prompt
	div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.react-draggable.MuiPaper-elevation24.MuiPaper-rounded {
	max-height: 300px;
	min-height: 200px;
	min-width: 500px;
	max-width: 500px;
}

div#dialog-prompt div#draggable-dialog-title,
div#dialog-prompt div.MuiDialogActions-root.makeStyles-dialogGradientBlueBackground-41.MuiDialogActions-spacing {
	padding-top: 2px;
	padding-bottom: 2px;
}
