.logo {
	margin: 0 auto;
	font-size: 2em;
}
.form {
	width: 100%; // Fix IE 11 issue.
	margin-top: 24px;
}
.submit {
	width: 100%;
}
