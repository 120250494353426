@import "./Bootstrap";
@import "bootstrap/scss/bootstrap";

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

/* For triple ultrawides 5120*2160 each except one tiny one (3840*1600) */
@media (min-width: 14000px) and (max-width: 15000px) {
	#overall-source-panel {
		width: 36.364% !important;
		max-width: none !important;
	}

	#overall-workspace-panel {
		width: 36.364% !important;
	}

	#overall-destination-panel {
		width: 27.272% !important;
	}
}

/* For triple ultrawides 5120*2160 each */
@media (min-width: 15360px) and (max-width: 15361px) {
	#overall-source-panel {
		width: 33.333% !important;
		max-width: none !important;
	}

	#overall-workspace-panel {
		width: 33.333% !important;
	}

	#overall-destination-panel {
		width: 33.333% !important;
	}
}

div.root,
div.App,
div.content,
div#mfi,
div#row > table {
	width: 100%;
}

div.content {
	background-color: white;
	width: 90%;
	min-width: 1480px;
	margin: 0 auto;
	padding-top: 10px;
	overflow: auto;
}

div#header {
	box-shadow: 0 3px 10px 0 rgba(194, 229, 250, 0.82);
	display: flex;
	justify-content: space-between;
}

div#router-div {
	width: 100%;
	height: 100%;
}

nav#router-nav {
	height: 47px;
}

#navbar-ul {
	display: flex;
	align-items: center;
	padding: 0px;
	color: white;
}

.MuiInput-multiline {
	height: 100%;
}

.d-none {
	display: none !important;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.font-red {
	color: red;
}

.font-bold {
	font-weight: bold;
}

.full-width,
div.full-width {
	width: 100%;
}

.full-height,
div.full-height {
	height: 100%;
}

.left-text {
	text-align: left;
}

#navbar-ul a:hover {
	text-decoration: underline;
}

#navbar-ul a:visited,
#navbar-ul a {
	color: #fff;
	font-family: "Helvetica", "Arial", sans-serif;
}

* {
	font-family: "Helvetica", "Arial", sans-serif !important;
}

div#logo-img {
	color: white;
	background-image: url("../images/bmcl-logo-glow-brighter.png");
	background-size: contain;
	width: 41px;
	height: 41px;
}

div#header > div#logo div.localhost-logo {
	background-image: url("../images/bmcl-logo-red-glow.png");
}

div#logo {
	display: flex;
	padding-left: 15px;
	padding-top: 3px;
}

div#data-warehouse-logo-text {
	padding-left: 5px;
}

div#data-warehouse-logo-text p {
	margin-bottom: 0px;
	margin-top: 0px;
	/*font-size: 2.5vw;*/
	font-weight: 600;
	color: #fff;
	font-family: cursive;
}

div#root {
	height: 100%;
	background-color: white;
}

nav#router-nav {
	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#002b58), to(#125399));
}

.flex {
	display: flex;
}

.App-header {
	/*background-color: #282c34;*/
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

/*Animations*/
/*Enter*/
@-webkit-keyframes scale-in-center {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes scale-in-center {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}

.scale-in-center {
	-webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*Exit*/
@-webkit-keyframes scale-out-center {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}

	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
}

@keyframes scale-out-center {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}

	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
}

.scale-out-center {
	-webkit-animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
	animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

td.changed,
tr.changed {
	border-color: #ffff00;
	box-shadow: 0 0 10px #ffff00;
}

a.underline-link {
	text-decoration: underline;
}

a.no-underline {
	text-decoration: none;
}

a.icon-link:hover {
	color: #0a58ca !important;
}

@media print {
	div.content {
		height: 100%;
		overflow: visible;
	}
}

/* These styles were createad for components managed by the variable input field */
.checklist-input {
	border: 0;
	outline: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	height: 100%;
	width: 100%;
}

.checklist-input:focus {
	border: 0;
	outline: none !important;
	box-shadow: none;
}

.fade-overflow {
	min-width: 0;
	margin-right: 10px;
	mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
	text-overflow: clip;
}

//style.css

/* Form */

#sidebar-scrollbar {
	height: 100vh;
}

.scroll-content div.p-3 {
	height: 100px;
}

.iq-sidebar-menu .iq-menu .iq-menu-title {
	display: block;
	color: #374948;
	font-size: 15px;
	position: relative;
	padding: 15px 20px 15px 30px;
	font-weight: 500;
	line-height: 18px;
}

.iq-sidebar-menu {
	position: relative;
}

.iq-sidebar-menu .iq-menu {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.iq-sidebar-menu .iq-menu li {
	list-style-type: none;
	position: relative;
}

.panelHeader h2 {
	margin-left: 45px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.iq-sidebar-menu .iq-menu li a {
	// font-size: 14px;
	// white-space: nowrap;
	// display: block;
	// color: #777D74;
	// position: relative;
	// padding: 15px 20px 15px 30px;
	// line-height: 18px;
	// display: -webkit-box;
	// display: -ms-flexbox;
	// display: flex;
	// -webkit-box-align: center;
	// -ms-flex-align: center;
	// align-items: center;
	// overflow: hidden;

	font-size: 14px;
	white-space: nowrap;
	display: block;
	color: #777d74;
	position: relative;
	line-height: 18px;
	display: flex;
	align-items: center;
	overflow: hidden;
}

.iq-sidebar-menu .iq-menu li > a::before {
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	content: "";
	width: 3px;
	height: 0;
	display: inline-block;
	background: #0084ff;
	position: absolute;
	top: 0;
	right: 0;
	opacity: 1;
}

.iq-sidebar-menu .iq-menu li.active > a::before {
	opacity: 1;
	height: 100%;
}

.iq-sidebar-menu .iq-menu li a i {
	font-size: 20px;
	margin-right: 10px;
	vertical-align: middle;
	width: 20px;
	display: inline-block;
	float: left;
}

.iq-sidebar-menu .iq-menu li a span {
	white-space: nowrap;
	padding: 0;
	display: inline-block;
	float: left;
}

.iq-sidebar-menu .iq-menu li a .iq-arrow-right {
	margin-right: 0;
	margin-left: auto;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
}

.iq-sidebar-menu .iq-menu li a .badge {
	position: absolute;
	right: 15px;
	-webkit-transition: all 0.9s ease-out 0s;
	-moz-transition: all 0.9s ease-out 0s;
	-ms-transition: all 0.9s ease-out 0s;
	-o-transition: all 0.9s ease-out 0s;
	transition: all 0.9s ease-out 0s;
}

.iq-sidebar-menu .iq-menu li.active {
	border-bottom: none;
}

.iq-sidebar-menu .iq-menu li a:hover,
.iq-sidebar-menu .iq-menu li.active > a,
.iq-sidebar-menu .iq-menu li.menu-open > a {
	color: #0084ff;
	background: #e5f2ff;
	text-decoration: none;
}

.iq-sidebar-menu .iq-menu li a[aria-expanded="true"] .iq-arrow-right,
.iq-sidebar-menu .iq-menu li.menu-open .iq-arrow-right,
.iq-sidebar-menu .iq-menu li.active .iq-arrow-right {
	transform: rotate(90deg);
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
}

.iq-sidebar-menu .iq-menu li .iq-submenu li a:hover,
.iq-sidebar-menu .iq-menu li.active .iq-submenu li > a {
	background: transparent;
}

.iq-sidebar-menu .iq-menu li .iq-submenu li > a::before {
	display: none;
}

/*------------------------*/

.iq-sidebar-menu .iq-menu li ul {
	padding-left: 40px;
}

.iq-sidebar-menu .iq-menu li ul li a {
	color: #777d74;
	background: #ffffff;
	padding: 12px 20px;
}

.iq-sidebar-menu .iq-menu li ul a:hover {
	color: #0084ff;
	text-decoration: none;
	background: transparent;
}

/*---------Sidebar Dark---------------*/

.iq-sidebar.sidebar-dark,
.iq-sidebar.sidebar-dark .iq-sidebar-logo {
	background: #151b21;
	color: #ffffff;
}

.iq-sidebar.sidebar-dark .iq-sidebar-logo a span {
	color: rgba(255, 255, 255, 0.8);
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu .iq-menu-title {
	color: rgba(255, 255, 255, 0.4);
}

.iq-sidebar.sidebar-dark .iq-menu li > a::before {
	display: none;
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li > a:hover,
.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li.active > a,
.iq-sidebar.sidebar-dark .iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li a {
	color: #ffffff;
	background: #0f1317;
}

.iq-sidebar.sidebar-dark .iq-menu li a:hover,
.iq-sidebar.sidebar-dark .iq-menu li.active > a,
.iq-sidebar.sidebar-dark .iq-menu li.menu-open > a {
	color: #ffffff;
	background: #0f1317;
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li a {
	color: rgba(255, 255, 255, 0.6);
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li ul li a {
	background: transparent;
	padding-left: 60px;
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li ul {
	padding-left: 0;
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li.active .iq-submenu li > a {
	background: transparent;
}

.iq-sidebar.sidebar-dark .ripple {
	background: rgba(255, 255, 255, 0.1);
}

.iq-sidebar.sidebar-dark .iq-menu-bt {
	background: #0f1317;
}

.iq-sidebar.sidebar-dark .line-menu {
	background: #ffffff;
}

/*---------Sidebar Dark blue ---------------*/

.iq-sidebar.sidebar-dark-blue,
.iq-sidebar.sidebar-dark-blue .iq-sidebar-logo {
	background: #6d73f6;
	color: #ffffff;
}

.iq-sidebar.sidebar-dark-blue .iq-sidebar-logo a span {
	color: rgba(255, 255, 255, 0.8);
}

.iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu .iq-menu-title {
	color: rgba(255, 255, 255, 0.4);
}

.iq-sidebar.sidebar-dark-blue .iq-menu li > a::before {
	display: none;
}

.iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu li > a:hover,
.iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu li.active > a,
.iq-sidebar.sidebar-dark-blue .iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu li a {
	color: #ffffff;
	background: #5a61fd;
}

.iq-sidebar.sidebar-dark-blue .iq-menu li a:hover,
.iq-sidebar.sidebar-dark-blue .iq-menu li.active > a,
.iq-sidebar.sidebar-dark-blue .iq-menu li.menu-open > a {
	color: #ffffff;
	background: #0f1317;
}

.iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu li a {
	color: rgba(255, 255, 255, 0.9);
}

.iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu li ul li a {
	background: transparent;
	padding-left: 15px;
}

.iq-sidebar.sidebar-dark-blue .iq-sidebar-menu .iq-menu li ul {
	padding-left: 0;
}

.iq-sidebar.sidebar-dark-blue .ripple {
	background: rgba(255, 255, 255, 0.1);
}

.iq-sidebar.sidebar-dark-blue .iq-menu-bt {
	background: #0f1317;
}

.iq-sidebar.sidebar-dark-blue .line-menu {
	background: #ffffff;
}

/*---------------------------------------------------------------------
                             Top Menu
-----------------------------------------------------------------------*/

.iq-top-navbar .iq-navbar-custom .iq-menu-bt {
	display: none;
}

.iq-top-navbar {
	border-radius: 0;
	margin-bottom: 0;
	padding: 0;
	min-height: 50px;
	background: #ffffff;
	-webkit-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
	-moz-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
	box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
}

.iq-top-navbar .iq-navbar-custom .navbar-breadcrumb {
	margin: 15px 0 0 30px;
	float: left;
}

.iq-top-navbar .breadcrumb .breadcrumb-item.active {
	color: #777d74;
}

ol.breadcrumb li:last-child button {
	pointer-events: none;
	color: black;
}

.iq-top-navbar .breadcrumb {
	background: transparent;
	padding: 0;
	margin: 0;
}

.iq-top-navbar .breadcrumb li {
	list-style-type: none;
	font-size: 12px;
}

.breadcrumb-item + .breadcrumb-item::before {
	content: "❯";
	vertical-align: sub;
	padding: 0px 5px;
}

.breadcrumb-item + .breadcrumb-item {
	padding: 0px !important;
}

.iq-top-navbar .breadcrumb li button,
.iq-top-navbar .breadcrumb li button:hover {
	text-decoration: none;
}

.navbar-list {
	margin: 0;
	padding: 0;
	float: right;
}

.navbar-list li {
	float: left;
	list-style-type: none;
	position: relative;
}

.navbar-list li .dropdown-toggle::after {
	display: none;
}

.navbar-list li > a,
.navbar-list li > button {
	font-size: 18px;
	padding: 0 20px;
	line-height: 76px;
	color: #0084ff;
	display: block;
	height: 100%;
	//min-height: 45px;
}

.menu-tag {
	position: absolute;
	background: #374948;
	color: #ffffff;
	display: inline-block;
	top: 18px;
	left: 18px;
	font-size: 12px;
	border-radius: 90px;
	line-height: normal;
	height: 18px;
	width: 18px;
	line-height: 18px;
	text-align: center;
	display: inline-block;
}

.navbar-list li a:hover {
	text-decoration: none;
}

.navbar-list li img {
	width: 40px;
}

.navbar-list li.iq-nav-user {
	display: flex;
	align-items: flex-start;
}

.navbar-list li.iq-nav-user span {
	flex: 1;
	font-size: 14px;
}

.iq-breadcrumb {
	display: none;
}

.nav-item span.dots {
	height: 5px;
	width: 5px;
	font-size: 0;
	text-align: center;
	padding: 0;
	position: absolute;
	top: 25px;
	right: 10px;
	animation: shadow-pulse-dots 1s infinite;
	border-radius: 50%;
	-webkit-border-radius: 50%;
}

.nav-item .badge.count-mail {
	height: 15px;
	width: 15px;
	font-size: 10px;
	text-align: center;
	line-height: 15px;
	padding: 0;
	position: absolute;
	top: 20px;
	right: 10px;
	animation: shadow-pulse 1s infinite;
}

@keyframes shadow-pulse-dots {
	0% {
		box-shadow: 0 0 0 0px rgba(230, 65, 65, 0.2);
	}

	100% {
		box-shadow: 0 0 0 15px rgba(230, 65, 65, 0);
	}
}

@keyframes shadow-pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 132, 255, 0.2);
	}

	100% {
		box-shadow: 0 0 0 15px rgba(0, 132, 255, 0);
	}
}

/*---------- Sidebar Logo --------------*/

.iq-sidebar-logo {
	display: inline-block;
	width: 350px;
	height: 77px;
	//width: 260px;
	//padding: 5px 20px 5px 0;
	float: left;
	//position: relative;
	background: #ffffff;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}

.iq-sidebar-logo a {
	display: flex;
	align-items: flex-start;
	background-image: url(https://bmcl.ubmobjectworkspace.com/static/media/bmcl-logo-glow-brighter.png);
	background-position: top left;
	background-size: contain;
	background-repeat: no-repeat;
	margin-left: 2px;
	margin-top: 2px;
}

.iq-sidebar-logo a span {
	margin-left: 55px;
	margin-top: 2px;
	margin-bottom: -2px;
	font-size: 28px;
	line-height: 45px;
	color: #374948;
	font-family: "Questrial", sans-serif;
	flex: 1;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}

.iq-sidebar-logo a:hover,
.iq-sidebar-logo a:focus {
	text-decoration: none;
}

.iq-sidebar-logo img {
	height: 45px;
}

.iq-menu-bt {
	background: #e5f2ff;
	padding: 12px 10px;
	margin-right: 15px;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.wrapper-menu {
	color: #0084ff;
	width: 20px;
	height: 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
	transition: transform 330ms ease-out;
}

.wrapper-menu.open {
	transform: rotate(-45deg);
}

.line-menu {
	background-color: #0084ff;
	border-radius: 90px;
	width: 100%;
	height: 2px;
}

.line-menu.half {
	width: 50%;
}

.line-menu.start {
	transition: transform 330ms cubic-bezier(0.54, -0.81, 0.37, 0.37);
	transform-origin: right;
}

.open .line-menu.start {
	transform: rotate(-90deg) translateX(3px);
}

.line-menu.end {
	align-self: flex-end;
	transition: transform 330ms cubic-bezier(0.54, -0.81, 0.37, 0.37);
	transform-origin: left;
}

.open .line-menu.end {
	transform: rotate(-90deg) translateX(-3px);
}

/*----------------------*/

.iq-top-navbar .iq-sub-dropdown {
	width: 300px;
	display: none;
	position: absolute;
	top: 100%;
	z-index: 2;
	right: 0;
	background-color: #ffffff;
	padding: 0;
	border-radius: 4px;
	-webkit-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
	-moz-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
	box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
}

/* ----------------------------------------------
 * Generated by Animista on 2019-12-22 15:30:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */

@-webkit-keyframes fade-in-bottom {
	0% {
	}

	45% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		opacity: 0;
	}

	55% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}

	100% {
	}
}

@keyframes fade-in-bottom {
	0% {
	}

	45% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		opacity: 0;
	}

	55% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}

	100% {
	}
}

.iq-show {
	background-color: #dbeeff;
}

.iq-show .iq-sub-dropdown {
	display: inline-block;
}

.navbar-list li .iq-sub-dropdown .iq-sub-card {
	font-size: inherit;
	padding: 15px;
	line-height: normal;
	color: inherit;
	border-bottom: 1px solid #f3f7fd;
	display: inline-block;
	width: 100%;
}

.navbar-list li .iq-sub-dropdown a.iq-sub-card {
	text-decoration: none;
}

.iq-top-navbar .search-box {
	display: none;
	position: absolute;
	top: 100%;
	right: 0;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	background-color: #ffffff;
	transition: all;
	padding: 15px;
	-webkit-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
	-moz-box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
	box-shadow: 0px 0px 25px 0px rgba(45, 69, 95, 0.1);
}

.iq-show .search-box {
	display: inline-block;
}

.search-box .search-input {
	width: 300px;
	height: 50px;
	padding: 0 1em;
	border: 1px solid #d4e3f9;
	background-color: #f3f7fd;
	opacity: 1;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

.iq-sub-dropdown .iq-card-icon {
	width: 45px;
	height: 45px;
	line-height: 45px;
	font-size: 22px;
}

//responsive.css

/*---------------------------------------------------------------------
Horizontal Menu Page
-----------------------------------------------------------------------*/

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li {
	position: relative;
}

.iq-page-menu-horizontal .content-page {
	margin-left: 0;
}

.iq-page-menu-horizontal .iq-footer {
	margin-left: 0;
}

.iq-page-menu-horizontal .iq-sidebar-logo {
	padding-top: 0;
}

.iq-page-menu-horizontal .iq-top-navbar .iq-sidebar-logo .top-logo {
	display: block;
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li ul {
	display: block;
	position: absolute;
	top: 100%;
	padding: 0;
	min-width: 200px;
	background: #fff;
	z-index: 9;
	box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
	transition: all 0.15s ease-in 0s;
	-webkit-transition: all 0.15s ease-in 0s;
	-moz-transition: all 0.15s ease-in 0s;
	-o-transition: all 0.15s ease-in 0s;
	transform: scale(1, 0);
	-webkit-transform: scale(1, 0);
	-moz-transform: scale(1, 0);
	-o-transform: scale(1, 0);
	-webkit-transform-origin: 0 0 0;
	-moz-transform-origin: 0 0 0;
	-o-transform-origin: 0 0 0;
	transform-origin: 0 0 0;
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li ul #sidebar-scrollbar {
	height: 50vh;
}

.iq-menu-horizontal .iq-sidebar-menu li.menu-open > a,
.iq-menu-horizontal .iq-sidebar-menu li:hover > a {
	color: #0084ff;
	background: transparent;
}

.iq-menu-horizontal .iq-sidebar-menu li:hover > .iq-submenu {
	display: block;
	transition: all 0.15s ease-in 0s;
	-webkit-transition: all 0.15s ease-in 0s;
	-moz-transition: all 0.15s ease-in 0s;
	-o-transition: all 0.15s ease-in 0s;
	transform: scale(1, 1);
	-webkit-transform: scale(1, 1);
	-moz-transform: scale(1, 1);
	-o-transform: scale(1, 1);
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li.menu-open .iq-arrow-right,
.iq-menu-horizontal .iq-sidebar-menu .iq-menu li:hover .iq-arrow-right {
	transform: rotate(90deg);
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li li .iq-arrow-right,
.iq-menu-horizontal .iq-sidebar-menu .iq-menu li:hover li .iq-arrow-right {
	transform: rotate(0deg);
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li li a .iq-arrow-right {
	transform: rotate(0);
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li ul li a {
	padding: 12px 15px;
}

.iq-menu-horizontal .iq-sidebar-menu li > .iq-submenu.iq-submenu-data {
	opacity: 0;
	visibility: hidden;
	left: 100%;
	top: 0;
	transition: transform 0.2s;
	transform: translateX(-50px);
}

.iq-menu-horizontal .iq-sidebar-menu li:hover > .iq-submenu.iq-submenu-data {
	opacity: 1;
	visibility: visible;
	transition: all 0.45s ease 0s;
	transform: translateX(0);
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li a:hover,
.iq-menu-horizontal .iq-sidebar-menu .iq-menu li.active > a,
.iq-menu-horizontal .iq-sidebar-menu .iq-menu li.menu-open > a {
	background: transparent;
}

.iq-menu-horizontal .iq-sidebar-menu .iq-menu li > a::before {
	display: none;
}

li.softbox-menu {
	padding: 0px;
}

a.disabled {
	pointer-events: none;
	cursor: default;
	opacity: 0.6;
}

a.disabled:hover,
a.disabled,
a.disabled:focus {
	color: #777d74 !important;
}

//typography
/*
Template: Sofbox - Responsive Bootstrap 4 Admin Dashboard Template
Author: iqonicthemes.in
Design and Developed by: iqonicthemes.in
NOTE: This file contains the styling for responsive Template.
*/

/*================================================
[  Table of contents  ]
================================================

:: General
:: Input
:: Loading
:: Background Color
:: Background Color Opacity
:: Text Color
:: Line Height
:: Font Size
:: Avatar Size
:: Margin Bottom
:: Card
:: Background overlay color
:: Buttons
:: Alert
:: list Group
:: Grid Boxes

======================================
[ End table content ]
======================================*/

/*---------------------------------------------------------------------
General
-----------------------------------------------------------------------*/
*::-moz-selection {
	background: #0084ff;
	color: #ffffff;
	text-shadow: none;
}

::-moz-selection {
	background: #0084ff;
	color: #ffffff;
	text-shadow: none;
}

::selection {
	background: #0084ff;
	color: #ffffff;
	text-shadow: none;
}

body {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 14px;
	padding: 0;
	margin: 0;
	background: #f3f7fd;
}

a,
.btn {
	-webkit-transition: all 0.5s ease-out 0s;
	-moz-transition: all 0.5s ease-out 0s;
	-ms-transition: all 0.5s ease-out 0s;
	-o-transition: all 0.5s ease-out 0s;
	transition: all 0.5s ease-out 0s;
}

.uppercase {
	text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Questrial", sans-serif;
	font-weight: 600;
	margin: 0px;
	color: #3e3e3e;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
}

label {
	font-weight: normal;
}

h1 {
	font-size: 3.052em;
}

h2 {
	font-size: 2.3em;
}

h3 {
	font-size: 1.953em;
}

h4 {
	//font-size: 1.400em;
	font-size: 1.07142857rem;
}

h5 {
	font-size: 1.2em;
}

h6 {
	font-size: 1em;
}

/* Definition Lists */
dl dd {
	margin-bottom: 15px;
}

dl dd:last-child {
	margin-bottom: 0px;
}

th {
	font-weight: 600;
}

/*----------------------------------------------
loading
------------------------------------------------*/
//#loading { opacity: 1; background-color: #ffffff; height: 100%; width: 100%; position: fixed; margin-top: 0px; top: 0px; left: 0px; bottom: 0px; overflow: hidden !important; right: 0px; z-index: 999999; -webkit-animation: fadeOut 1s; animation: fadeOut 1s;  animation-fill-mode: forwards; }
//@-webkit-keyframes fadeOut {
//  0% { opacity: 1;}
//  99% { opacity: 0.01;width: 100%; height: 100%;}
//  100% { opacity: 0;width: 0; height: 0;}
//}
//@keyframes fadeOut {
//  0% { opacity: 1;}
//  99% { opacity: 0.01;width: 100%; height: 100%;}
//  100% { opacity: 0;width: 0; height: 0;}
//}

#loading-center {
	width: 100%;
	height: 100%;
	position: relative;
}

#loading-center img {
	width: 100px;
	text-align: center;
	left: 0;
	position: absolute;
	right: 0;
	top: 50%;
	bottom: 50%;
	z-index: 99;
	margin: auto;
	-webkit-animation: spin 4s linear infinite;
	-moz-animation: spin 4s linear infinite;
	animation: spin 4s linear infinite;
}

.right-sidebar-mini {
	top: 0;
	z-index: 100;
	position: fixed;
	width: 350px;
	right: 0;
	transform: translateX(calc(111% + -2em));
	transition: all 0.5s ease;
}

.right-sidebar-mini .side-right-icon {
	display: none;
}

.right-sidebar-toggle {
	position: absolute;
	margin-left: -44px;
	background: #fff;
	padding: 15px;
	display: inline;
	top: 15%;
	z-index: 99;
	border-radius: 30px 0px 0px 30px;
	box-shadow: -10px 5px 20px rgba(0, 0, 0, 0.19);
	cursor: pointer;
}

.right-sidebar-panel {
	background-color: #fff;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	height: 100vh;
	padding: 15px;
	overflow-y: scroll;
}

.right-sidebar {
	transform: translateX(calc(10% + -1em));
}

.right-sidebar .side-left-icon {
	display: none;
}

.right-sidebar .side-right-icon {
	display: block;
}

/*----------------------------------------------
Background Color
------------------------------------------------*/
//.bg-primary, .badge-primary { background-color: #0084ff !important; }
.bg-primary,
.badge-primary {
	background-color: #0084ffde !important;
}

.bg-secondary,
.badge-secondary {
	background-color: #777d74 !important;
}

.bg-success,
.badge-success {
	background-color: #00ca00 !important;
}

.bg-danger,
.badge-danger {
	background-color: #e64141 !important;
}

.bg-warning,
.badge-warning {
	background-color: #ffd400 !important;
}

.bg-info,
.badge-info {
	background-color: #00d0ff !important;
}

.bg-light,
.badge-light {
	background-color: #e9edf4 !important;
}

.bg-dark,
.badge-dark {
	background-color: #374948 !important;
}

/*----------------------------------------------
Custom Background Color
------------------------------------------------*/
.bg-cyan,
.badge-cyan {
	background-color: #0cd2e3 !important;
	color: #ffffff !important;
}

.bg-cobalt-blue,
.badge-cobalt-blue {
	background-color: #3f79f1 !important;
	color: #ffffff !important;
}

.bg-spring-green,
.badge-spring-green {
	background-color: #1be1b3 !important;
	color: #ffffff !important;
}

.bg-amber,
.badge-amber {
	background-color: #f4b72a !important;
	color: #ffffff !important;
}

.bg-pink,
.badge-pink {
	background-color: #fd6c9e !important;
	color: #ffffff !important;
}

/*----------------------------------------------
Background Color Opacity
------------------------------------------------*/
.iq-bg-primary {
	background: #e5f2ff !important;
	color: #0084ff !important;
}

.iq-bg-secondary {
	background: #f1f2f1 !important;
	color: #777d74 !important;
}

.iq-bg-success {
	background: #e5fae5 !important;
	color: #00ca00 !important;
}

.iq-bg-danger {
	background: #fcecec !important;
	color: #e64141 !important;
}

.iq-bg-warning {
	background: #fffbe5 !important;
	color: #ffd400 !important;
}

.iq-bg-info {
	background: #e5faff !important;
	color: #00d0ff !important;
}

.iq-bg-dark {
	background: #ebecec !important;
	color: #374948 !important;
}

/*--------------*/
.iq-bg-primary-hover:hover {
	background: #e5f2ff !important;
}

.iq-bg-primary-secondary-hover:hover {
	background: #f1f2f1 !important;
}

.iq-bg-primary-success-hover:hover {
	background: #e5fae5 !important;
}

.iq-bg-primary-danger-hover:hover {
	background: #fcecec !important;
}

.iq-bg-primary-warning-hover:hover {
	background: #fffbe5 !important;
}

.iq-bg-primary-info-hover:hover {
	background: #e5faff !important;
}

.iq-bg-primary-dark-hover:hover {
	background: #ebecec !important;
}

/*----------------------------------------------
Text Color
------------------------------------------------*/
.text-primary {
	color: #0084ff !important;
}

.text-secondary {
	color: #777d74 !important;
}

.text-success {
	color: hsl(143, 65%, 27%) !important;
}

.text-danger {
	color: #e64141 !important;
}

.text-warning {
	color: #ffd400 !important;
}

.text-info {
	color: #00d0ff !important;
}

.text-light {
	color: #e9edf4 !important;
}

.text-dark {
	color: #374948 !important;
}

.text-white {
	color: #ffffff !important;
}

/*----------------------------------------------
Custom Text Color
------------------------------------------------*/
.text-cyan {
	color: #0cd2e3 !important;
}

.text-cobalt-blue {
	color: #3f79f1 !important;
}

.text-spring-green {
	color: #1be1b3 !important;
}

.text-amber {
	color: #f4b72a !important;
}

.text-pink {
	color: #fd6c9e !important;
}

/*----------------------------------------------
Line Height
------------------------------------------------*/
.line-height {
	line-height: normal;
}

.line-height-2 {
	line-height: 2;
}

.line-height-4 {
	line-height: 40px;
}

.line-height-6 {
	line-height: 60px;
}

/*----------------------------------------------
Font Size
------------------------------------------------*/
.font-size-12 {
	font-size: 12px !important;
}

.font-size-14 {
	font-size: 14px !important;
}

.font-size-16 {
	font-size: 16px !important;
}

.font-size-18 {
	font-size: 18px !important;
}

.font-size-32 {
	font-size: 32px !important;
}

.font-size-40 {
	font-size: 40px !important;
}

/*----------------------------------------------
Avatar Size
------------------------------------------------*/
.avatar-35 {
	height: 35px;
	width: 35px;
	line-height: 35px;
	font-size: 0.5rem;
}

.avatar-30 {
	height: 30px;
	width: 30px;
	line-height: 30px;
	font-size: 0.4rem;
}

.avatar-40 {
	height: 40px;
	width: 40px;
	line-height: 40px;
	font-size: 0.6rem;
}

.avatar-45 {
	height: 45px;
	width: 45px;
	line-height: 45px;
	font-size: 0.8rem;
}

.avatar-50 {
	height: 50px;
	width: 50px;
	line-height: 50px;
	font-size: 1rem;
}

.avatar-60 {
	height: 60px;
	width: 60px;
	line-height: 60px;
	font-size: 1.2rem;
}

.avatar-70 {
	height: 70px;
	width: 70px;
	line-height: 70px;
	font-size: 1.4rem;
}

.avatar-80 {
	height: 80px;
	width: 80px;
	line-height: 80px;
	font-size: 1.6rem;
}

.avatar-90 {
	height: 90px;
	width: 90px;
	line-height: 90px;
	font-size: 1.6rem;
}

.avatar-100 {
	height: 100px;
	width: 100px;
	line-height: 100px;
	font-size: 1.6rem;
}

.avatar-110 {
	height: 110px;
	width: 110px;
	line-height: 110px;
	font-size: 1.6rem;
}

.avatar-120 {
	height: 120px;
	width: 120px;
	line-height: 120px;
	font-size: 1.6rem;
}

.avatar-130 {
	height: 130px;
	width: 130px;
	line-height: 130px;
	font-size: 1.6rem;
}

/*----------------------------------------------
Margin Bottom
------------------------------------------------*/
.iq-mb-3 {
	margin-bottom: 30px !important;
}

/*----------------------------------------------
Card
------------------------------------------------*/
.iq-card {
	background: #ffffff;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin-bottom: 30px;
	border: none;
	-webkit-box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
	-moz-box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
	box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
}

.iq-card-body {
	padding: 20px;
}

.iq-card .iq-card-header {
	padding: 0 20px;
	min-height: 60px;
	border-bottom: 1px solid #f2edff;
	-ms-flex-align: center !important;
	align-items: center !important;
}

.iq-card .iq-card-header .iq-header-title {
	display: inline-block;
	align-self: center !important;
}

.iq-card .iq-card-header .iq-header-title .card-title {
	margin-bottom: 0;
}

.iq-card-header-toolbar .nav-item a {
	color: #393c52;
	padding: 4px 12px;
	font-size: 14px;
	font-weight: 600;
	font-family: "Nunito", sans-serif;
}

.iq-card-header-toolbar .dropdown-toggle i {
	font-size: 22px;
	line-height: normal;
	color: #393c52;
}

.iq-card-header-toolbar .dropdown-toggle::after {
	display: none;
}

/*--------------*/
.nav-pills .nav-item a {
	color: #393c52;
	font-weight: 600;
	font-family: "Questrial", sans-serif;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	color: #0084ff;
	background-color: #e5f2ff;
}

.nav-pills .nav-link:hover {
	color: #0084ff;
}

/*--------------*/
.nav-tabs {
	border-bottom: 2px solid #f2edff;
	margin-bottom: 15px;
}

.nav-tabs .nav-item {
	margin-bottom: -2px;
}

.nav-tabs .nav-item a {
	color: #393c52;
	font-weight: 600;
	font-family: "Questrial", sans-serif;
	border: none;
	border-bottom: 2px solid transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	border-bottom: 2px solid #0084ff;
	color: #0084ff;
}

/*---------------------------------------------------------------------
Background overlay color
-----------------------------------------------------------------------*/
.parallax {
	background-size: cover !important;
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-ms-background-size: cover !important;
	position: relative;
	z-index: 0;
	background-origin: initial;
	background-position: center center !important;
	background-repeat: no-repeat;
	background-attachment: fixed !important;
}

/* Background Gradient BLACK */
.bg-over-black-10:before {
	background: rgba(5, 8, 9, 0.1);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-20:before {
	background: rgba(5, 8, 9, 0.2);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-30:before {
	background: rgba(5, 8, 9, 0.3);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-40:before {
	background: rgba(5, 8, 9, 0.4);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-50:before {
	background: rgba(5, 8, 9, 0.5);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-60:before {
	background: rgba(5, 8, 9, 0.6);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-70:before {
	background: rgba(5, 8, 9, 0.7);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-80:before {
	background: rgba(5, 8, 9, 0.8);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-85:before {
	background: rgba(5, 8, 9, 0.85);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

.bg-over-black-90:before {
	background: rgba(5, 8, 9, 0.9);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

/*----------------------------------------------
Buttons
------------------------------------------------*/

.btn.focus,
.btn:focus {
	box-shadow: none !important;
}

.iq-sign-btn {
	display: inline-block;
	text-align: center;
	border: 1px solid transparent;
	padding: 0.375rem 1rem;
	border-radius: 0.25rem;
}

.iq-sign-btn:hover {
	color: #ffffff !important;
	background: #e64141 !important;
}

/*----------------------------------------------
Alert
------------------------------------------------*/
.alert-primary {
	color: #0084ff;
	border-color: #b2daff;
	background-color: #e5f2ff;
}

.alert-secondary {
	color: #777d74;
	border-color: #d6d8d5;
	background-color: #f1f2f1;
}

.alert-success {
	color: #218838;
	border-color: #b3e6be;
	background-color: #e5fae5;
}

.alert-danger {
	color: #e64141;
	border-color: #f7c6c6;
	background-color: #fcecec;
}

.alert-warning {
	color: #ffd400;
	border-color: #fff2b2;
	background-color: #fffbe5;
}

.alert-info {
	color: #00d0ff;
	border-color: #b2f1ff;
	background-color: #e5faff;
}

.alert-light {
	color: #777d74;
	border-color: #d6d8d5;
	background-color: #e9edf4;
}

.alert-dark {
	color: #374948;
	border-color: #c3c8c8;
	background-color: #ebecec;
}

/*--------------*/
.alert {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.alert .iq-alert-icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 1.3rem 0 0;
}

.alert .iq-alert-icon i {
	font-size: 2.441em;
	line-height: normal;
}

.alert .iq-alert-text {
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.alert .close {
	float: right;
	font-size: 20px;
	font-weight: 400;
	line-height: 1;
	color: #fff;
	text-shadow: none;
	opacity: 1;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
	outline: none;
}

/*----------------------------------------------
list Group
------------------------------------------------*/
.list-group-item-primary {
	background-color: #e5f2ff;
	color: #0084ff;
}

.list-group-item-secondary {
	color: #777d74;
	background-color: #f1f2f1;
}

.list-group-item-success {
	color: #218838;
	background-color: #e5fae5;
}

.list-group-item-danger {
	color: #e64141;
	background-color: #fcecec;
}

.list-group-item-warning {
	color: #ffd400;
	background-color: #fffbe5;
}

.list-group-item-info {
	color: #00d0ff;
	background-color: #e5faff;
}

.list-group-item-light {
	color: #777d74;
	background-color: #e9edf4;
}

.list-group-item-dark {
	color: #374948;
	background-color: #ebecec;
}

.list-group-item-action {
	color: #374948;
}

.list-group-item.active {
	background-color: #0084ff;
	border-color: #0084ff;
}

/*----------------------------------------------
Grid Boxes
------------------------------------------------*/
.iq-card-block.iq-card-stretch {
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.iq-card-block {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.iq-menu-horizontal .iq-sidebar-menu li.menu-open > a,
.iq-menu-horizontal .iq-sidebar-menu li:hover {
	background-color: #e2f1ff;
}

.MuiPaper-rounded {
	border-radius: 2px !important;
	border: solid 1px rgb(239, 239, 239) !important;
}

.MuiFormControl-root,
.MuiAutocomplete-root {
	margin: 5px 0 !important;
	width: 100%;
}

ul.panelHeaderTitle {
	list-style: none;
	padding: 5px 0px;
	display: table;
	margin: auto;
	font-size: 15px;
}

.panelHeader::after {
	content: "";
	display: table;
	clear: both;
}

.panelHeaderTitle li {
	display: table-row;
	text-align: right;
	color: rgb(60, 60, 60);
}

.panelHeaderTitle b {
	display: table-cell;
	padding-left: 4px;
	text-align: left;
}

.panelHeader {
	background-color: #ebecec;
}

.extendedPanelHeader {
	background-color: #e2e8f2;
}

.panelElement {
	display: flex;
	flex-direction: column;
}

.panelBody {
	flex: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
}

.breadcrumb {
	margin: 0;
	color: #0d6efd;

	div.content {
		height: 100%;
		overflow: visible;
	}
}

b .breadcrumb {
	font-weight: 700;
}

.icon-link {
	margin: 8px;
}

.xIcon:hover {
	color: red;
}

.text-red {
	color: red;
}

.unstyled-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

// Better looking scrollbars (from semantic)
/* Force Simple Scrollbars */

body ::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 10px;
	height: 10px;
}

body ::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0.1);
	border-radius: 0px;
}

body ::-webkit-scrollbar-thumb {
	cursor: pointer;
	border-radius: 5px;
	background: rgba(0, 0, 0, 0.25);
	-webkit-transition: color 0.2s ease;
	transition: color 0.2s ease;
}

body ::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(0, 0, 0, 0.15);
}

body ::-webkit-scrollbar-thumb:hover {
	background: rgba(128, 135, 139, 0.8);
}

/* Inverted UI */

body .ui.inverted::-webkit-scrollbar-track {
	background: rgba(255, 255, 255, 0.1);
}

body .ui.inverted::-webkit-scrollbar-thumb {
	background: rgba(255, 255, 255, 0.25);
}

body .ui.inverted::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(255, 255, 255, 0.15);
}

body .ui.inverted::-webkit-scrollbar-thumb:hover {
	background: rgba(255, 255, 255, 0.35);
}

// Change react grid layout resize color from default red to blue
.react-grid-item.react-grid-placeholder {
	background: #0084ff !important;
}

div.overlay-fade {
	opacity: 0.5;
	border-radius: 5px;
	border: 5px solid RGB(0 68 255);
}

div#overlay,
div#datawarehouse-panel div.tree,
div#template-panel div.tree,
div#workspace-panel div.tree {
	width: 100%;
}

div#overlay {
	display: flex;
	text-align: center;
	vertical-align: middle;
	align-items: center;
	justify-content: center;
	font-weight: 900;
	font-size: 1.5em;
	pointer-events: none;
	font-family: Courier, Georgia, Serif, serif;
}

.quickActions {
	border-left: 1px solid gainsboro;
	border-right: 1px solid gainsboro;
}

.watermark {
	position: fixed;
	top: 20%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.6;
	line-height: 3em;
	font-size: 300px;
	letter-spacing: 2px;
	z-index: 99;
	color: rgb(249, 152, 152);
}

.fill-cell {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	display: block;
}

button.open-upload-button:focus {
	border: 1px solid $input-focus-border-color;
	box-shadow: $input-focus-box-shadow !important;
}

.removeLinkStyles {
	text-decoration: none;
	color: inherit;
}

.MuiTooltip-tooltip.MUILight-tooltip {
	background-color: #fff;
	color: #000;
	border: 1px solid #000;
	box-shadow: 0 0 10px #616161;
}
