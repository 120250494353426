.Online-color {
	color: rgb(11, 171, 11);
}

.Offline-color {
	color: red;
}

.Checking-color {
	color: rgb(71, 107, 225);
}

.onlineIndicator {
	display: inline;
	padding: 0px 2px;
	font-size: x-small;
	cursor: default;
}
