.Online-color {
	color: rgb(11, 171, 11);
}

.Offline-color {
	color: red;
}

.Checking-color {
	color: rgb(71, 107, 225);
}

.onlineIndicator {
	display: inline;
	padding: 0px 2px;
	font-size: x-small;
	cursor: default;
}

.notification {
	max-width: 600px;
	margin: 0.3rem auto;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
	border-radius: 0.5rem;
}
.header {
	display: flex;
	justify-content: space-between;
	padding: 0.5rem 1rem;
	/* border-bottom: 1px solid #e9ecef96; */
}
.content {
	padding: 1rem;
}
.secondaryText {
	font-size: 0.85rem;
	color: #6c757d;
}
.notificationAction {
	width: 200px;
}

.spaceBetween {
	justify-content: space-between;
}

.read {
	background-color: #ebf3ff;
}
